import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {kebabCase} from "lodash";
import Img from "components/image";
import Link from "components/link";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS} from "constants/index";
import style from "styles/pages/products.module.css";
// import incidentResponseSm from "images/solutions-incident-response-sm.svg";
// import incidentResponseLg from "images/solutions-incident-response-lg.svg";
import securityAssessmentSm from "images/solutions-security-assessment-sm.svg";
import securityAssessmentLg from "images/solutions-security-assessment-lg.svg";
import penetrationTestingLg from "images/solutions/solutions-pentest-logo.png";
import penetrationTestingSm from "images/solutions-penetration-testing-sm.svg";
import regulatoryComplianceSm from "images/solutions-regulatory-compliance-sm.svg";
import regulatoryComplianceLg from "images/solutions-regulatory-compliance-lg.svg";
import dataPrivacySm from "images/solutions-data-privacy-sm.svg";
import dataPrivacyLg from "images/solutions-data-privacy-lg.svg";
// import cryptocurrencySecuritySm from "images/solutions-cryptocurrency-security-sm.svg";
// import cryptocurrencySecurityLg from "images/solutions-cryptocurrency-security-lg.svg";

function SolutionsPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  let solutionSections = pageContent.solutions.map((item, index) => {
    if (index % 2 === 1) item.reverse = true;
    return <SolutionSection key={item.headerText} {...item}/>;
  });
  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <section className="relative bg-contain bg-center bg-no-repeat min-h-screen flex items-center">
      <div className="container mx-auto px-8 z-10 relative py-40">
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight whitespace-pre-wrap">
          {pageContent.headerText}
        </h3>
        <div className="text-center">
          <Link to={pageContent.ctaUrl} className="btn btn-orange mt-6 inline-block">
            {pageContent.ctaText}
          </Link>
        </div>
      </div>
      <Img {...IMAGE_BACKGROUND_PROPS} objectFit="contain" alt="Background"
        fluid={data.backgroundImage.childImageSharp.fluid}/>
    </section>
    {solutionSections}
    <div className="pb-48"/>
  </DefaultLayout>;
}

function SolutionSection(props) {
  let images;
  let reverseClassName = "";
  if (props.reverse === true) {
    reverseClassName = "md:flex-row-reverse";
  }
  // if (/Incident Response/i.test(props.headerText)) {
  //   images = <React.Fragment>
  //     <img className="block md:hidden pointer-events-none" src={incidentResponseSm}
  //       alt={props.headerText}/>
  //     <img className="absolute hidden md:block pointer-events-none" src={incidentResponseLg}
  //       alt={props.headerText}
  //       style={{maxWidth: "initial", height: "400px", top: "60px", right: "-40px"}}/>
  //   </React.Fragment>;
  // }
  if (/vCISO/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={securityAssessmentSm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={securityAssessmentLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "600px", top: "80px", right: "-260px"}}/>
    </React.Fragment>;
  }
  if (/Penetration Testing/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={penetrationTestingSm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={penetrationTestingLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "460px", top: "-100px", right: "20px"}}/>
    </React.Fragment>;
  }
  if (/Compliance/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={regulatoryComplianceSm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={regulatoryComplianceLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "330px", top: "40px", right: "-220px"}}/>
    </React.Fragment>;
  }
  if (/Data Privacy/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={dataPrivacySm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={dataPrivacyLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "440px", top: "-140px", right: "20px"}}/>
    </React.Fragment>;
  }
  // if (/Crypto Security/i.test(props.headerText)) {
  //   images = <React.Fragment>
  //     <img className="block md:hidden pointer-events-none" src={cryptocurrencySecuritySm}
  //       alt={props.headerText}/>
  //     <img className="absolute hidden md:block pointer-events-none" src={cryptocurrencySecurityLg}
  //       alt={props.headerText}
  //       style={{maxWidth: "initial", height: "500px", top: "-100px", right: "0"}}/>
  //   </React.Fragment>;
  // }
  return <section id={kebabCase(props.headerText)} className="min-h-screen flex items-center">
    <div className="container mx-auto px-8 py-24 z-10 relative max-w-4xl">
      <div className={`flex ${reverseClassName} flex-col md:flex-row items-center`}>
        <div className={`${style.productImageContainer} -mb-12 md:mb-0 relative flex-none`}>
          {images}
        </div>
        <div>
          <h3 className="font-bold text-3xl md:text-5xl leading-tight">
            {props.headerText}
          </h3>
          <p className="mt-2">{props.descriptionText}</p>
          {returnLink(props)}
        </div>
      </div>
    </div>
  </section>;
}

function returnLink(props) {
  const {ctaText, ctaUrl} = props;
  return (
    <Link className="btn btn-orange-outline inline-block mt-6" to={ctaUrl}>
      {ctaText}
     </Link>
  );
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: {eq: "pages/solutions.json"}) {
      childPagesJson {
        title
        description
        headerText
        ctaText
        ctaUrl
        solutions {
          headerText
          descriptionText
          ctaText
          ctaUrl
        }
      }
    }
    backgroundImage: file(relativePath: {eq: "background-solutions-1.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: JPG) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default React.memo(SolutionsPage);
